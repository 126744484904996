(function($) {
    $(document).ready(function () {
        const toTop = $('#to-top');
        const sideNav = $('#side-nav');
        const sideNavMarker = $('#side-nav-marker');
        const header = $('#header');

        toTop.click(function () {
            $('html, body').animate({ scrollTop: 0 }, 300);
        });

        // ---------------------------------------------------
        // ready
        // ---------------------------------------------------
        scrollFunction();

        // ---------------------------------------------------
        // scroll
        // ---------------------------------------------------
        function scrollFunction() {
            var windowScrollTop = $(window).scrollTop();

            if (windowScrollTop > 200) {
                header.addClass('scrolled');
            } else {
                header.removeClass('scrolled');
            }

            //
            // side nav
            //
            if (sideNavMarker.length > 0) {
                if (windowScrollTop > (sideNavMarker.offset().top - 130)) {
                    sideNav.addClass('fixed');
                } else {
                    sideNav.removeClass('fixed');
                }
            }

            var headlineH1 = $('h1');
            headlineH1.each(function () {
                var thisHeadline = headlineH1;
                if (thisHeadline.hasClass('highligh-done') === false) {
                    thisHeadline.find('.color-blue').lettering('letters');
                    if (isIntoView(thisHeadline)) {
                        var timeOut = 0;
                        thisHeadline.find('.color-blue span').each(function() {
                            var thisSpan = $(this);
                            setTimeout(function () {
                                thisSpan.addClass('highlight');
                            },timeOut);
                            timeOut += 100;
                        });

                        thisHeadline.addClass('highligh-done');
                    }
                }
            });

        }

        $(window).scroll(function () {
            scrollFunction();
        });

        // ---------------------------------------------------
        // hamburger
        // ---------------------------------------------------
        const hamburger = $('#hamburger');
        hamburger.click(function (e) {
            e.preventDefault();
            hamburger.toggleClass('no-hamburger');
            $('#nav-overlay').toggleClass('in');
            header.toggleClass('nav-overlay-in');
        });

        // ---------------------------------------------------
        // execute at document ready
        // ---------------------------------------------------
        $('#nav-overlay').find('.nav-block-title.w-icon').click(function () {
            $(this).parent().toggleClass('open');
        });

        /**
         * if element is in viewport
         *
         * @param elem
         * @returns {boolean}
         */
        function isIntoView(elem) {
            var documentViewTop = $(window).scrollTop();
            var documentViewBottom = documentViewTop + $(window).height();

            var elementTop = $(elem).offset().top;
            var elementBottom = elementTop + $(elem).height();

            return ((elementBottom <= documentViewBottom) && (elementTop >= documentViewTop));
        }

        // ---------------------------------------------------
        // onepager navi
        // ---------------------------------------------------
        $('.js-onepager-nav-item').click(function (e) {
            e.preventDefault();
            var scrollTarget = 0;
            var thisOnepagerLink = $(this).attr('data-onepager-link');

            if ( thisOnepagerLink === 'top' ) {
                scrollTarget = 0;
            } else {
                scrollTarget = $('#' + thisOnepagerLink).offset().top;
            }

            $('html, body').animate({ scrollTop: scrollTarget }, 300);
        });


        // ---------------------------------------------------
        // borlabs cookie
        // ---------------------------------------------------
        $('.js-cookie-box').click(function(e) {
            e.preventDefault();
            window.BorlabsCookie.showCookieBox();
        });

        var modReasons = $('.mod-reasons');
        if (modReasons.length > 0) {
            var reasons = modReasons.find('.reason');
            reasons.hover(
                function() {
                var no = $(this).attr('data-reason');
                modReasons.find('div[data-reason="' + no + '"]').addClass('hover');
            },
                function() {
                    var no = $(this).attr('data-reason');
                    modReasons.find('div[data-reason="' + no + '"]').removeClass('hover');
            });
        }

        var headlineH1 = $('h1');
        headlineH1.find('.color-blue').lettering('letters');
        var timeOut = 0;

        // if (isIntoView(headlineH1)) {
        //     console.log("not");
        // headlineH1.find('.color-blue span').each(function() {
        //     var thisSpan = $(this);
        //     setTimeout(function () {
        //         thisSpan.addClass('highlight');
        //     },timeOut);
        //     timeOut += 100;
        // });

        // } else {
        //     console.log("not");
        // }

    });
}(jQuery));
